import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { gql } from 'graphql-tag';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppState } from '@services/appstate.service';
import { ImageModel } from '@models/image';
import { TagModel } from '@models/tag';
import { getImageSearchBookmark } from '../reducers';
import { tokenGetter } from '../app.module';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class ImageGraphqlService {
    uploadUrl = 'https://lh54hegf4k.execute-api.eu-central-1.amazonaws.com/v1';

    constructor(
        private apollo: Apollo,
        private store: Store<AppState>,
        private http: HttpClient,
        private notificationService: NotificationService
    ) {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (profile != null && profile['https://mediaspirit.io/app_metadata'] != null) {
            if (profile['https://mediaspirit.io/app_metadata'].testEnvironment === true) {
                this.uploadUrl = 'https://n4bkuvmbta.execute-api.eu-central-1.amazonaws.com/v1';
            }
        }
    }

    // Image retrieving
    retrieveImage(imageId: any) {
        const apolloQuery = gql`
            query Image($imageId: ID!) {
                image(id: $imageId) {
                    code
                    success
                    errors {
                        message
                        type
                        locations {
                            line
                            column
                            filepath
                        }
                    }
                    image {
                        id
                        name
                        nlCleanUrl
                        nlDescription
                        fileSize
                        height
                        width
                        uploadedAt
                        inProgress
                        tags {
                            id
                            name
                            className
                        }
                        storageId
                        recognitionClasses {
                            class
                            score
                        }
                        lastProcessedTransformState {
                            mirror {
                                horizontal
                                vertical
                            }
                            rotation
                        }
                        lastProcessedFocusPoint {
                            percentageX
                            percentageY
                        }
                        focusPoint {
                            percentageX
                            percentageY
                        }
                        imageCropResolutions {
                            cropResolution {
                                id
                                path
                                url
                            }
                            path
                        }
                        process {
                            crop {
                                done
                                inProgress
                                pending 
                            }
                            cognitive {
                                done
                                inProgress
                                pending 
                            }
                            upload {
                                done
                                inProgress
                                pending 
                            }
                            delete {
                                done
                                inProgress
                                pending 
                            }
                        }
                        rawRecognitionData
                        priority
                        attachments
                    }
                }
            }
        `;
        return this.apollo.query({
            query: apolloQuery,
            variables: { imageId: imageId }
        });
    }

    getImages(searchObj: any) {
        let sortType;
        if (searchObj.sortType === 'newest') {
            sortType = 'DESC';
        } else if (searchObj.sortType === 'oldest') {
            sortType = 'ASC';
        }

        let bookmark
        if (searchObj.type !== 'initial') {
            this.store.pipe(getImageSearchBookmark()).pipe(take(1)).subscribe(bm => {
                const res: any = bm;
                bookmark = res
            });
        }

        const tempSearchObj: any = {
            recordsLimit: 20
        }
        if (sortType != null) {
            tempSearchObj.sortBy = sortType;
        }
        if (bookmark != null) {
            tempSearchObj.bookmark = bookmark;
        }
        if (searchObj.query.indexOf('url:(') > -1) {
            tempSearchObj.url = searchObj.query.replace(/.*\s?url\:\(/, '(').replace(/\).*/, ')');
            searchObj.query = searchObj.query.replace(/url\:\(.*?\)/, '');
        }
        if (searchObj.query.indexOf('name:(') > -1) {
            tempSearchObj.name = searchObj.query.replace(/.*\s?name\:\(/, '(').replace(/\).*/, ')');
            searchObj.query = searchObj.query.replace(/name\:\(.*?\)/, '');
        }
        if (searchObj.query.indexOf('description:(') > -1) {
            tempSearchObj.description = searchObj.query.replace(/.*\s?description\:\(/, '(').replace(/\).*/, ')');
            searchObj.query = searchObj.query.replace(/description\:\(.*?\)/, '');
        }
        if (searchObj.query.indexOf('uploadedAt:[') > -1) {
            tempSearchObj.uploadedAt = searchObj.query.replace(/.*\s?uploadedAt\:\[/, '[').replace(/\].*/, ']');
            searchObj.query = searchObj.query.replace(/uploadedAt\:\[.*?\]/, '');
        }
        if (searchObj.query.indexOf('NOT tag:(') > -1) {
            tempSearchObj.notTag = searchObj.query.replace(/.*\s?NOT tag\:\(/, '(').replace(/\).*/, ')');
            searchObj.query = searchObj.query.replace(/NOT tag\:\(.*?\)/, '');
        }
        if (searchObj.query.indexOf('tag:(') > -1) {
            tempSearchObj.tag = searchObj.query.replace(/.*\s?tag\:\(/, '(').replace(/\).*/, ')');
            searchObj.query = searchObj.query.replace(/tag\:\(.*?\)/, '');
        }
        if (searchObj.query.indexOf('cognitiveTags:(') > -1) {
            tempSearchObj.cognitiveTags = searchObj.query.replace(/.*\s?cognitiveTags\:\(/, '(').replace(/\).*/, ')');
            searchObj.query = searchObj.query.replace(/cognitiveTags\:\(.*?\)/, '');
            switch (searchObj.cognitiveAccuracy) {
                case 30:
                    tempSearchObj.cognitiveTagsAccuracy = 'THIRTY';
                    break;
                case 40:
                    tempSearchObj.cognitiveTagsAccuracy = 'FOURTY';
                    break;
                case 50:
                    tempSearchObj.cognitiveTagsAccuracy = 'FIFTY';
                    break;
                case 60:
                    tempSearchObj.cognitiveTagsAccuracy = 'SIXTY';
                    break;
                case 70:
                    tempSearchObj.cognitiveTagsAccuracy = 'SEVENTY';
                    break;
                case 80:
                    tempSearchObj.cognitiveTagsAccuracy = 'EIGHTY';
                    break;
                case 90:
                    tempSearchObj.cognitiveTagsAccuracy = 'NINETY';
                    break;
            }
        }

        const apolloQuery = gql`
			query images($searchObj: Search!) {
				images(search: $searchObj) {
                    code
                    success
                    errors {
                        message
                        type
                    }
                    bookmark
                    total
                    image {
                        id
                        name
                        nlCleanUrl
                        fileSize
                        height
                        width
                        uploadedAt
                        inProgress
                        tags {
                        id
                        name
                        className
                        }
                        imageCropResolutions {
                            cropResolution {
                              id
                              path
                              url
                            }
                            path
                        }
                        storageId
                        process {
                            crop {
                                done
                                inProgress
                                pending 
                            }
                            cognitive {
                                done
                                inProgress
                                pending 
                            }
                            upload {
                                done
                                inProgress
                                pending 
                            }
                            delete {
                                done
                                inProgress
                                pending 
                            }
                        }
                        priority
                        attachments
                    }
				}
			}
        `;
        return this.apollo.query({
            query: apolloQuery,
            variables: { searchObj: tempSearchObj }
        });
    }

    getImagesUnlimited(query: any, bookmark: string = null, sortType: string, cognitiveAccuracy: number) {
        if (sortType === 'newest') {
            sortType = 'DESC';
        } else if (sortType === 'oldest') {
            sortType = 'ASC';
        }

        const tempSearchObj: any = {
            recordsLimit: 200
        }
        if (sortType != null) {
            tempSearchObj.sortBy = sortType;
        }
        if (bookmark != null) {
            tempSearchObj.bookmark = bookmark;
        }
        if (query.indexOf('url:(') > -1) {
            tempSearchObj.url = query.replace(/.*\s?url\:\(/, '(').replace(/\).*/, ')');
            query = query.replace(/url\:\(.*?\)/, '');
        }
        if (query.indexOf('name:(') > -1) {
            tempSearchObj.name = query.replace(/.*\s?name\:\(/, '(').replace(/\).*/, ')');
            query = query.replace(/name\:\(.*?\)/, '');
        }
        if (query.indexOf('description:(') > -1) {
            tempSearchObj.description = query.replace(/.*\s?description\:\(/, '(').replace(/\).*/, ')');
            query = query.replace(/description\:\(.*?\)/, '');
        }
        if (query.indexOf('uploadedAt:[') > -1) {
            tempSearchObj.uploadedAt = query.replace(/.*\s?uploadedAt\:\[/, '[').replace(/\].*/, ']');
            query = query.replace(/uploadedAt\:\[.*?\]/, '');
        }
        if (query.indexOf('NOT tag:(') > -1) {
            tempSearchObj.notTag = query.replace(/.*\s?NOT tag\:\(/, '(').replace(/\).*/, ')');
            query = query.replace(/NOT tag\:\(.*?\)/, '');
        }
        if (query.indexOf('tag:(') > -1) {
            tempSearchObj.tag = query.replace(/.*\s?tag\:\(/, '(').replace(/\).*/, ')');
            query = query.replace(/tag\:\(.*?\)/, '');
        }
        if (query.indexOf('cognitiveTags:(') > -1) {
            tempSearchObj.cognitiveTags = query.replace(/.*\s?cognitiveTags\:\(/, '(').replace(/\).*/, ')');
            query = query.replace(/cognitiveTags\:\(.*?\)/, '');
            switch (cognitiveAccuracy) {
                case 30:
                    tempSearchObj.cognitiveTagsAccuracy = 'THIRTY';
                    break;
                case 40:
                    tempSearchObj.cognitiveTagsAccuracy = 'FOURTY';
                    break;
                case 50:
                    tempSearchObj.cognitiveTagsAccuracy = 'FIFTY';
                    break;
                case 60:
                    tempSearchObj.cognitiveTagsAccuracy = 'SIXTY';
                    break;
                case 70:
                    tempSearchObj.cognitiveTagsAccuracy = 'SEVENTY';
                    break;
                case 80:
                    tempSearchObj.cognitiveTagsAccuracy = 'EIGHTY';
                    break;
                case 90:
                    tempSearchObj.cognitiveTagsAccuracy = 'NINETY';
                    break;
            }
        }

        const apolloQuery = gql`
			query images($searchObj: Search!) {
				images(search: $searchObj) {
                    code
                    success
                    errors {
                        message
                        type
                    }
                    bookmark
                    total
                    image {
                        id
                        name
                        nlCleanUrl
                        fileSize
                        height
                        width
                        uploadedAt
                        inProgress
                        tags {
                        id
                        name
                        className
                        }
                        imageCropResolutions {
                            cropResolution {
                              id
                              path
                              url
                            }
                            path
                        }
                        storageId
                        process {
                            crop {
                                done
                                inProgress
                                pending 
                            }
                            cognitive {
                                done
                                inProgress
                                pending 
                            }
                            upload {
                                done
                                inProgress
                                pending 
                            }
                            delete {
                                done
                                inProgress
                                pending 
                            }
                        }
                        priority
                        attachments
                    }
				}
			}
        `;
        return this.apollo.query({
            query: apolloQuery,
            variables: { searchObj: tempSearchObj }
        });
    }

    getDeletedImages(bookmark: string) {
        const tempSearchObj: any = {
            recordsLimit: 200,
            trashed: true,
            sortBy: 'DESC',
            bookmark
        }
        const apolloQuery = gql`
            query images($searchObj: Search!) {
                images(search: $searchObj) {
                    code
                    success
                    errors {
                        message
                        type
                    }
                    bookmark
                    total
                    image {
                        id
                        name
                        nlCleanUrl
                        fileSize
                        height
                        width
                        uploadedAt
                        inProgress
                        tags {
                        id
                        name
                        className
                        }
                        imageCropResolutions {
                            cropResolution {
                            id
                            path
                            url
                            }
                            path
                        }
                        storageId
                        process {
                            crop {
                                done
                                inProgress
                                pending 
                            }
                            cognitive {
                                done
                                inProgress
                                pending 
                            }
                            upload {
                                done
                                inProgress
                                pending 
                            }
                            delete {
                                done
                                inProgress
                                pending 
                            }
                        }
                        priority
                        attachments
                    }
                }
            }
        `;
        return this.apollo.query({
            query: apolloQuery,
            variables: { searchObj: tempSearchObj }
        });
    }

    getFailedCrops() {
        // const tempSearchObj: any = {
        //     recordsLimit: 200,
        //     trashed: true,
        //     sortBy: 'DESC'
        // }
        const apolloQuery = gql`
            query FailedCrops {
                failedCrops {
                    code
                    success
                    errors {
                        message
                        type
                    }
                    total
                    image {
                        id
                        nlCleanUrl
                        uploadedAt
                    }
                }
            }
        `;
        return this.apollo.query({
            query: apolloQuery
        });
    }

    updateImage(image: ImageModel) {
        return Promise.resolve(image); // this is now being used for optimistic UI, it triggers UPDATE_IMAGE_SUCCESS
    }

    // Image mutations
    trashImage(id: string) {
        const apolloQuery = gql`
            mutation TrashImage {
                trashImage(id: "${id}") {
                    code
                    success
                    errors {
                        message
                        type
                    }
                    image {
                        id
                        tags {
                            id
                            name
                            className
                        }
                    }
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery
        })
    }

    trashMultipleImages(imageIds: [string]) {
        const apolloQuery = gql`
            mutation BulkTrashImages($imageIds: [ID!]!) {
                bulkTrashImages(imageIds: $imageIds) {
                    code
                    success
                    errors {
                        message
                        type
                        locations {
                            line
                            column
                            filepath
                        }
                    }
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { imageIds }
        })
    }

    deleteImage(id: string) {
        const apolloQuery = gql`
            mutation StartImageDelete {
                startImageDelete(id: "${id}") {
                    code
                    success
                    errors {
                        message
                        type
                    }
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery
        })
    }

    recoverImage(id: string) {
        const apolloQuery = gql`
            mutation RecoverImage {
                recoverImage(id: "${id}") {
                    code
                    success
                    errors {
                        message
                        type
                    }
                    image {
                        id
                    }
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery
        })
    }

    updateImageProperties(object: any) {
        const imageId = object.id;
        const apolloQuery = gql`
        mutation UpdateImage($updateImageId: ID!, $image: UpdateImageData!) {
            updateImage(id: $updateImageId, image: $image) {
                code
                errors {
                    message
                    type
                }
                image {
                    id
                }
            }
        }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { updateImageId: imageId, image: object.properties }
        }).toPromise().then((result: any) => {
            if (result != null && result.data != null && result.data.updateImage != null && result.data.updateImage.code === 201) {
                if (!object.image.process) {
                    object.image.process = {
                        moveCrops: {}
                    };
                } else if (!object.image.process.moveCrops) {
                    object.image.moveCrops = {};
                }
                object.image.process.moveCrops.done = true;
            }
            if (object.image != null && object.image.process != null && object.image.process.moveCrops != null && object.image.process.moveCrops.done != null) {
                object.image.process.moveCrops.done = true;
            }
            if (object.image != null) {
                return Promise.resolve(object.image);
            } else {
                return Promise.resolve(object);
            }
        });
    }

    updateImageFocuspoint(object: any) {
        const imageId = object.id;
        const apolloQuery = gql`
        mutation UpdateImageFocusPoint($updateImageId: ID!, $imageFocusPoint: ImageFocusPoint!) {
            updateImageFocusPoint(id: $updateImageId, imageFocusPoint: $imageFocusPoint) {
                code
                errors {
                    message
                    type
                }
                image {
                    id
                }
            }
        }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { updateImageId: imageId, imageFocusPoint: object.properties.focusPoint }
        }).toPromise().then(() => {
            if (object.image != null) {
                return Promise.resolve(object.image);
            } else {
                return Promise.resolve(object);
            }
        });
    }

    updateImageCleanurl(object: any) {
        const imageId = object.id;
        const cleanUrl = object.properties.nlCleanUrl.replace('/', '').replace(/\..*/g, '');
        const apolloQuery = gql`
        mutation UpdateImageUrl($imageId: ID!, $cleanUrl: String!) {
            updateImageUrl(id: $imageId, url: $cleanUrl) {
                success
                errors {
                    message
                    type
                }
                image {
                    id
                }
            }
        }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { imageId, cleanUrl }
        }).toPromise().then((result: any) => {
            if (result != null && result.data != null && result.data.updateImageUrl != null && result.data.updateImageUrl.success === false) {
                this.notificationService.error('Url already found, please use another url after refreshing the page');
                setTimeout(() => {
                    location.reload();
                }, 3000);
            }
            if (object.image != null) {
                return Promise.resolve(object.image);
            } else {
                return Promise.resolve(object);
            }
        });
    }

    changeImagePriority(image: ImageModel, tag: any, priority: number) {
        let priorityObject: any = {};
        if (image.hasOwnProperty('priority') === true) {
            priorityObject = JSON.parse(JSON.stringify(image.priority));
        }
        if (priority !== 0 && priority != null) {
            priorityObject[tag.id] = priority;
        } else if (priority == null) {
            delete priorityObject[tag.id];
        }

        const apolloQuery = gql`
            mutation UpdatePriority($updatePriorityId: ID!, $priority: JSON!) {
                updatePriority(id: $updatePriorityId, priority: $priority) {
                    code
                    image {
                        id
                        name
                        priority
                    }
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { updatePriorityId: image.id, priority: priorityObject }
        })
    }

    startImageRecrop(imageId: string) {
        const apolloQuery = gql`
            mutation startImageRecrop($recropId: ID!) {
                startImageRecrop(id: $recropId) {
                    code
                    success
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { recropId: imageId }
        });
    }

    updatecognitiveRecognizeImage(imageId: string, imageRecognitionClasses: []) {
        const apolloQuery = gql`
            mutation UpdateImageRecognitionClasses($updateImageRecognitionClassesId: ID!, $imageRecognitionClasses: [ImageRecognitionClass]!) {
                updateImageRecognitionClasses(id: $updateImageRecognitionClassesId, imageRecognitionClasses: $imageRecognitionClasses) {
                    code
                    image {
                        id
                        name
                    }
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { updateImageRecognitionClassesId: imageId, imageRecognitionClasses: imageRecognitionClasses }
        });
    }

    startCognitiveRecognizeImage(imageId: string) {
        const apolloQuery = gql`
            mutation StartImageRecognition($imageId: ID!) {
                startImageRecognition(id: $imageId) {
                    code
                    errors {
                        message
                        type
                    }
                    success
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { imageId: imageId }
        });
    }

    // Image tag queries
    addImageTag(image: ImageModel, tag: TagModel) {
        const imageId = image.id;
        const tagObj = { className: tag.className, id: tag.id, name: tag.id };

        const apolloQuery = gql`
            mutation AddImageTag($imageId: ID!, $imageTag: ImageTag!) {
                addImageTag(imageId: $imageId, imageTag: $imageTag) {
                    code
                    success
                    errors {
                        message
                        type
                        locations {
                            line
                            column
                            filepath
                        }
                    }
                    image {
                        id
                        tags {
                            id
                            name
                            className
                        }
                    }
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { imageId, imageTag: tagObj }
        })
    }

    deleteImageTag(image: ImageModel, tag: TagModel) {
        const imageId = image.id;

        const apolloQuery = gql`
            mutation DeleteImageTag($imageId: ID!, $tagId: ID!) {
                deleteImageTag(imageId: $imageId, tagId: $tagId) {
                    code
                    success
                    errors {
                        message
                        type
                        locations {
                            line
                            column
                            filepath
                        }
                    }
                    image {
                        id
                        tags {
                            id
                            name
                            className
                        }
                        priority
                    }
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { imageId, tagId: tag.id }
        })
    }

    addMultipleImageTags(imageIds: any, tags: TagModel, action: string) {
        const apolloQuery = gql`
            mutation BulkUpdateTagImages($action: String!, $imageIds: [ID!]!, $tags: ImageTag) {
                bulkUpdateTagImages(action: $action, imageIds: $imageIds, tags: $tags) {
                    code
                    errors {
                        message
                        type
                    }
                    success
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { action: action, imageIds, tags: tags }
        });
    }

    // File uploading
    async uploadFile(file: File, fileSize: number, tags: string[]): Promise<any> {
        const header = {
            headers: new HttpHeaders().set('Authorization', `Bearer ${tokenGetter()}`)
        };
        const uploadStartResponse: any = await this.http.post(`${this.uploadUrl}/start-upload`, {
            fileName: file.name,
            contentType: 'application/octet-stream',
            fileSize
        }, header).toPromise();

        try {
            const chunkSize = uploadStartResponse.chunkSize;
            const amountOfChunks = Math.floor(fileSize / chunkSize) + 1;
            let start: any;
            let end: any;
            let blob: any;

            const uploadPartsArray: any = [];

            for (let index = 1; index < amountOfChunks + 1; index++) {
                start = (index - 1) * chunkSize;
                end = (index) * chunkSize;
                blob = (index < amountOfChunks) ? file.slice(start, end) : file.slice(start);

                const req = new HttpRequest('PUT', uploadStartResponse.urls[index - 1].url, blob, {
                    reportProgress: true
                });

                const response: any = await this.http.request(req).toPromise();
                uploadPartsArray.push({
                    ETag: response.headers.get('ETag').replace(/[|&;$%@"<>()+,]/g, ''),
                    PartNumber: uploadStartResponse.urls[index - 1].partNumber
                });

                if (uploadPartsArray.length === amountOfChunks) {
                    return await this.http.post(`${this.uploadUrl}/complete-upload`, {
                        fileName: file.name,
                        parts: uploadPartsArray.sort((a: any, b: any) => {
                            return a.PartNumber - b.PartNumber;
                        }),
                        uploadId: uploadStartResponse.uploadId,
                        tags: tags
                    }, header).toPromise()
                        .then(res => {
                            return res;
                        });
                }
            }
            return 'failed';
        } catch (e) {
            console.log('error: ', e);
            return 'failed';
        }
    }

    async reuploadEditedImage(imageData: any) {
        const file = imageData.updatedImage;
        const fileSize = imageData.updatedImage.size;
        const header = {
            headers: new HttpHeaders().set('Authorization', `Bearer ${tokenGetter()}`)
        };
        const uploadStartResponse: any = await this.http.post(`${this.uploadUrl}/start-upload`, {
            fileName: file.name,
            contentType: 'application/octet-stream',
            fileSize
        }, header).toPromise();

        try {
            const chunkSize = uploadStartResponse.chunkSize;
            const amountOfChunks = Math.floor(fileSize / chunkSize) + 1;
            let start: any;
            let end: any;
            let blob: any;

            const uploadPartsArray: any = [];

            for (let index = 1; index < amountOfChunks + 1; index++) {
                start = (index - 1) * chunkSize;
                end = (index) * chunkSize;
                blob = (index < amountOfChunks) ? file.slice(start, end) : file.slice(start);

                const req = new HttpRequest('PUT', uploadStartResponse.urls[index - 1].url, blob, {
                    reportProgress: true
                });

                const response: any = await this.http.request(req).toPromise();
                uploadPartsArray.push({
                    ETag: response.headers.get('ETag').replace(/[|&;$%@"<>()+,]/g, ''),
                    PartNumber: uploadStartResponse.urls[index - 1].partNumber
                });

                if (uploadPartsArray.length === amountOfChunks) {
                    return await this.http.post(`${this.uploadUrl}/complete-upload`, {
                        fileName: file.name,
                        parts: uploadPartsArray.sort((a: any, b: any) => {
                            return a.PartNumber - b.PartNumber;
                        }),
                        uploadId: uploadStartResponse.uploadId,
                        overrideImageId: imageData.id
                    }, header).toPromise()
                        .then(res => {
                            return res;
                        });
                }
            }
            return 'failed';
        } catch (e) {
            console.log('error: ', e);
            return 'failed';
        }
    }

    // File download
    async downloadSource(imageId: string) {
        const header = {
            headers: new HttpHeaders().set('Authorization', `Bearer ${tokenGetter()}`)
        };
        const downloadResponse: any = await this.http.post(`${this.uploadUrl}/source-download`, {
            imageId
        }, header).toPromise();
        if (downloadResponse != null && downloadResponse.url != null) {
            window.open(downloadResponse.url, '_blank');
        } else {
            this.notificationService.error('Image could not be downloaded, please try again after refreshing the page');
        }
    }
}
